import { useEffect } from 'react';
import { useAppSelector } from '@hooks/useAppSelector';
import * as referralCampaignActions from '@redux/modules/referralCampaign';
import { useActions } from '@utils/hooks';
import routes from '@utils/routes';
import { isEmpty } from '@utils/utils';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

const SignUpCoinPackStickyBanner = dynamic(
  () => import('@molecules/SignUpCoinPackStickyBanner')
);
const CoinPackCampaignStickyBanner = dynamic(
  () => import('@molecules/CoinPackCampaignStickyBanner')
);
const ReferralStickyBanner = dynamic(
  () => import('@molecules/ReferralStickyBanner')
);

const StickyBanners = ({ withBottomNav }: { withBottomNav: boolean }) => {
  const { authUser, loadingAuthUser, neighbor } = useAppSelector((state) => ({
    authUser: state.authUser,
    loadingAuthUser: state.loadingAuthUser,
    neighbor: state.neighbor,
  }));
  const { getReferralCampaign } = useActions({ ...referralCampaignActions });
  const { locale, pathname } = useRouter();

  const excludeRenderStickyBannersRoutes = [
    routes.invite,
    routes.inboxDetail,
    routes.property,
    routes.properties,
    routes.coinHistory,
    routes.propertyPlanConfirm,
    routes.propertyPlanPaymentOptions,
    routes.upgradePlan,
    routes.changePlan,
    routes.v2FlightReservationsNew,
    routes.v2FlightReservationsNewFlights,
    routes.v2FlightReservationsNewPassengers,
    routes.v2FlightReservationsNewConfirm,
    routes.welcomeBack,
    routes.invoice,
    routes.profileVerifyEmail,
    routes.profileRegistrationComplete,
    routes.subscribe,
    routes.travelStoryDetail,
    routes.travelStoryForm,
    routes.travelStories,
  ];

  const shouldRenderStickyBanners =
    !excludeRenderStickyBannersRoutes.includes(pathname);

  /* remove comment to enable referral campaign */
  useEffect(() => {
    if (shouldRenderStickyBanners && !loadingAuthUser) {
      getReferralCampaign(authUser, locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, loadingAuthUser, locale, shouldRenderStickyBanners]);

  if (isEmpty(neighbor) || !shouldRenderStickyBanners) {
    return null;
  }

  return (
    <>
      <ReferralStickyBanner withBottomNav={withBottomNav} />
      <SignUpCoinPackStickyBanner withBottomNav={withBottomNav} />
      <CoinPackCampaignStickyBanner withBottomNav={withBottomNav} />
    </>
  );
};

export default StickyBanners;
